section {
  padding: 60px 0;
}

.social-links {
  display: flex;
  gap: 10px;
  font-size: 24px;
}
.social-icon {
  color: #ccc;
  transition: color 0.3s;
}
.social-icon:hover {
  color: #fff; /* Change to your preferred hover color */
}

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/variables";
@import "components/navbar";
@import "../../node_modules/bootstrap/scss/bootstrap";
